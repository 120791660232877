<app-select-input
    label="{{ 'Categories' | translate }}"
    id="categories-selector"
    textField="label"
    valueField="value"
    [isMultiple]="true"
    [options]="mappedCategories()"
    [isRequired]="false"
    [isEditMode]="true"
    [value]="mappedSelectedCategories()"
    (valueChange)="onValueChange($event)" />
