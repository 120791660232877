import { Component } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { BaseViewComponent } from '@common/classes/base-view';
import { FaqPermissions } from '@common/classes/permissions';
import { toType } from '@common/models/util';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment';
import { ChipModule } from '@progress/kendo-angular-buttons';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FaqCategory } from 'app/faq/faq-categories.interface';
import { Faq, FaqSaveModel, Get } from 'app/faq/faq.service';
import { CommonModule } from '../../../common/common.module';
import { CategoriesSelectorComponent } from '../categories-selector/categories-selector.component';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';

@Component({
    selector: 'app-faq-view',
    templateUrl: './faq-view.component.html',
    standalone: true,
    providers: [CommonService],
    imports: [CommonModule, EditorModule, LanguageSelectorComponent, CategoriesSelectorComponent, ChipModule]
})
export class FaqViewComponent extends BaseViewComponent<Get> {
    languages = this.common.i18nService.languages;
    languageIds = this.languages.map((l) => l.languageId.toUpperCase());
    defaultLanguage = environment.defaultLanguage.toUpperCase();
    selectedLanguage = this.common.i18nService.currentLanguage();

    constructor(common: CommonService) {
        super(common);
    }

    // ========== BASEVIEW OVERRIDES START ==========
    override entityName = 'Faq';
    override createPermission = FaqPermissions.Action.Save;
    override editPermission = this.createPermission;
    override loadExtraParams = { includeAllTranslations: true };
    override initialModel: FaqSaveModel = {
        categories: [],
        translations: this.languageIds.map((lang) => {
            return { languageId: lang, question: null, answer: null };
        })
    };
    // map categoriesFull to categories and unwrap the faq item from the array since we only get one item
    override mapData = (data: Get) =>
        data.map(({ categoriesFull, translations, ...otherProps }) => ({
            ...otherProps,
            categories: categoriesFull,
            categoriesFull: null,
            translations: this.languageIds.map((langId) => {
                const translation = translations.find((t) => t.languageId === langId);
                return translation ? translation : { languageId: langId, question: '', answer: '' };
            })
        }))[0];
    override model: FaqSaveModel | Faq;
    override invalidateKey = (entityName: string) => entityName;
    override canSave(_x): boolean {
        const defaultTranslation = this.model.translations?.find((t) => t.languageId === this.defaultLanguage);
        if (!defaultTranslation?.question || !defaultTranslation?.answer) {
            this.toastrNotificationService.show(
                {
                    type: 'error',
                    message: _('Default language question and answer are required.')
                },
                { extendedTimeOut: 5000 }
            );
            return false;
        }
        return super.canSave(_x);
    }
    override getIdentifier() {
        return this.activeTranslation()?.question || '';
    }
    override navigateToViewMode(_?: any): Promise<boolean> {
        return super.navigateToList();
    }
    // ========== BASEVIEW OVERRIDES END ==========

    toFaqCategoryArray = (t: object) => toType<FaqCategory[]>(t);
    activeTranslation = () =>
        this.model.translations?.find(
            (t) =>
                t.languageId.localeCompare(this.selectedLanguage.languageId, undefined, { sensitivity: 'accent' }) === 0
        );
}
