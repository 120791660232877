import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { FaqCategory, FlattenedCategory } from 'app/faq/faq-categories.interface';
import { FaqCategoriesService } from 'app/faq/faq-categories.service';

@Component({
    selector: 'app-categories-page',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './categories-page.component.html',
    styles: ``
})
export class CategoriesPageComponent {
    categoriesService = inject(FaqCategoriesService);
    languageIds = this.categoriesService.languageIds;

    initialCategory = this.categoriesService.initialCategory;
    categoriesQuery = this.categoriesService.get({ includeAllTranslations: true });
    categoriesTranslations = computed<FaqCategory[]>(() =>
        this.categoriesService.populateCategoriesTranslations(this.categoriesQuery().data)
    );

    /**
     * Returns an array of categories where the key is the languageId and the value is the translation.
     */
    flattenedCategories = computed(() => {
        return this.categoriesService.mapToFlattenedCategories(this.categoriesTranslations());
    });

    getLanguageIconClass = this.categoriesService.getLanguageIconClass;

    saveHandler({ dataItem }: { dataItem: FlattenedCategory }) {
        if (dataItem?.id) {
            return this.categoriesService.update({
                id: dataItem.id,
                translations: this.categoriesService.flattenedTranslationsToTranslations(dataItem)
            });
        }
        return this.categoriesService.create({
            translations: this.categoriesService.flattenedTranslationsToTranslations(dataItem)
        });
    }

    removeHandler({ dataItem }: { dataItem: FlattenedCategory }) {
        this.categoriesService.remove({ id: dataItem.id });
    }
}
