import { Component, computed, inject, signal } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { BaseListComponent } from '@common/classes/base-list';
import { FaqPermissions } from '@common/classes/permissions';
import { CommonService } from '@services/common.service';
import { FaqCategoriesService } from 'app/faq/faq-categories.service';
import { Faq, FaqService } from 'app/faq/faq.service';
import Fuse from 'fuse.js';
import { CommonModule } from '../../../common/common.module';

@Component({
    templateUrl: './faq-list.component.html',
    standalone: true,
    providers: [CommonService],
    imports: [CommonModule]
})
export class FaqListComponent extends BaseListComponent {
    // ---------------- BaseList overrides
    override columns = null;
    override queryName = null;
    override initialize() {}
    override createPermission = FaqPermissions.Action.Save;
    // ---------------- BaseList overrides

    faq = inject(FaqService);
    faqCategories = inject(FaqCategoriesService);
    faqs = this.faq.get();
    categories = this.faqCategories.get({});

    fuse = new Fuse<Faq>([], {
        keys: ['question', 'answer'],
        threshold: 0.4,
        minMatchCharLength: 1,
        ignoreLocation: true
    });

    faqFilter = signal({ search: '', categories: [] });

    filteredFaqs = computed(() => {
        const search = this.faqFilter().search;
        const filterCategories = this.faqFilter().categories;
        const faqs = this.faqs().data?.sort((a, b) =>
            a.question?.localeCompare(b.question, undefined, { sensitivity: 'base' })
        );
        this.fuse.setCollection(faqs);
        const fuzzyResults = search ? this.fuse.search(search).map((result) => result.item) : faqs;
        const filteredBySelectedCategories = fuzzyResults.filter((faq) => {
            const categoriesIds = faq.categories.map((category) => category.id);
            if (filterCategories.length === 0) return true;
            return categoriesIds.some((id) => filterCategories.includes(id));
        });
        return filteredBySelectedCategories;
    });

    constructor(common: CommonService) {
        super(common);
    }

    async removeHandler(id: number) {
        const confirmed = await this.common.dialogService.confirm({
            options: {
                title: _('Remove FAQ'),
                message: _('Are you sure you want to remove this FAQ?')
            }
        });

        if (confirmed) {
            this.faq.remove({ id });
        }
    }

    categoriesSelectionHandler(categoriesArrayIndexes: number[]) {
        this.faqFilter.update((old) => ({
            ...old,
            categories: this.categories()
                .data.filter((_, index) => categoriesArrayIndexes.includes(index))
                .map((category) => category.id)
        }));
    }

    handleSearchChange() {
        // just trigger the signal
        // if we find a better way to trigger the signal detecing the input change we can use it
        this.faqFilter.update((old) => ({ ...old }));
    }
}
