<app-content-header [title]="getTitle()" [actionBar]="actionBar" />
<app-card *subscribe="query$ as query">
    @if (query?.isLoading && mode !== 'create') {
        <app-loader />
    } @else {
        <div class="flex flex-col gap-2">
            <app-language-selector
                [languages]="languages"
                [faqTranslations]="this.model.translations"
                [(selectedLanguage)]="selectedLanguage" />
            @if (editMode) {
                <div class="flex w-full flex-col flex-wrap gap-4 lg:flex-row">
                    <app-text-input
                        class="flex-1"
                        label="{{ 'Question' | translate }}"
                        id="question-input"
                        [isEditMode]="true"
                        [(value)]="activeTranslation().question" />
                    <app-categories-selector class="flex-1" [(selectedCategories)]="model.categories" />
                </div>
                <app-editor-input
                    class="mb-4 h-80 w-full"
                    label="{{ 'Answer' | translate }}"
                    id="answer"
                    [iframe]="false"
                    [isEditMode]="true"
                    [(value)]="activeTranslation().answer" />
            } @else {
                <h1 class="text-2xl">
                    @if (activeTranslation().question) {
                        {{ activeTranslation().question }}
                    } @else {
                        {{ 'Missing translation' | translate }}
                    }
                </h1>
                <div class="flex">
                    @for (c of toFaqCategoryArray(model.categories); track c.id) {
                        <kendo-chiplist>
                            <kendo-chip>
                                {{ c.category }}
                            </kendo-chip>
                        </kendo-chiplist>
                    }
                </div>
                @if (activeTranslation().answer) {
                    <app-editor-input
                        label="{{ 'Answer' | translate }}"
                        id="answer"
                        [value]="activeTranslation().answer"
                        [isEditMode]="false" />
                } @else {
                    <p>{{ 'Missing translation' | translate }}</p>
                }
            }
        </div>
    }
</app-card>
