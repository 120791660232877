import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from '@common/services/i18n.service';
import { BadgeModule } from '@progress/kendo-angular-indicators';
import { FaqTranslation } from 'app/faq/faq.service';
import { CommonModule } from '../../../common/common.module';

@Component({
    selector: 'app-language-selector',
    standalone: true,
    templateUrl: './language-selector.component.html',
    styles: ``,
    imports: [JsonPipe, CommonModule, BadgeModule]
})
export class LanguageSelectorComponent {
    @Input() languages!: Language[];
    @Input() selectedLanguage!: Language;
    @Input() faqTranslations: FaqTranslation[];
    @Output() selectedLanguageChange = new EventEmitter<Language>();

    selectLanguage(language: Language): void {
        this.selectedLanguage = language;
        this.selectedLanguageChange.emit(language);
    }

    isSelected(language: any): boolean {
        return this.selectedLanguage && this.selectedLanguage.languageId === language.languageId;
    }

    isLanguageTranslated(langId: string) {
        const translation = this.faqTranslations?.find((t) => t.languageId.toUpperCase() === langId.toUpperCase());
        return translation?.answer?.length && translation?.question?.length;
    }
}
