<div class="flex gap-2">
    @for (language of languages; track language.languageId) {
        <button
            class="rounded border border-primary px-1 py-1"
            [class.border-primary]="true"
            [ngClass]="{ 'border-transparent': !isSelected(language) }"
            (click)="selectLanguage(language)">
            <kendo-badge-container>
                <kendo-badge
                    size="medium"
                    rounded="full"
                    [themeColor]="isLanguageTranslated(language.languageId) ? 'success' : 'warning'"
                    [class]="'right-0.5 top-0.5'"></kendo-badge>
                <div class="fis !w-[1.33333em] rounded-full bg-repeat text-2xl" [ngClass]="language.iconClass"></div>
                <div class="text-xs font-semibold">{{ language.languageId.toUpperCase() }}</div>
            </kendo-badge-container>
        </button>
    }
</div>
