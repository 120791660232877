<app-content-header />

<app-editable-grid
    [data]="flattenedCategories()"
    [editMode]="true"
    [removeOnDelete]="false"
    [pageable]="false"
    [autoFitColumns]="false"
    [initialData]="initialCategory"
    (saveEvent)="saveHandler($event)"
    (removeEvent)="removeHandler($event)">
    @for (languageId of languageIds; track languageId) {
        <kendo-grid-column [field]="languageId" [title]="languageId">
            <ng-template kendoGridHeaderTemplate let-column>
                {{ column.field }}
                <div class="fi ml-2" [ngClass]="getLanguageIconClass(languageId)"></div>
            </ng-template>
            <ng-template kendoGridEditTemplate let-row="dataItem">
                <app-text-input
                    [isEditMode]="true"
                    [id]="'input-' + languageId + '-' + row.id"
                    [(value)]="row[languageId]" />
            </ng-template>
            <ng-template kendoGridCellTemplate let-row>
                {{ row[languageId] }}
            </ng-template>
        </kendo-grid-column>
    }
</app-editable-grid>
