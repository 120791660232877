import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { ViewMode } from '@common/models/view-mode';
import { CategoriesPageComponent } from './components/categories-page/categories-page.component';
import { FaqListComponent } from './components/faq-list/faq-list.component';
import { FaqViewComponent } from './components/faq-view/faq-view.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';

export const FAQ_ROUTES: Routes = [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    {
        path: 'create',
        component: FaqViewComponent,
        data: {
            mode: ViewMode.create
        }
    },
    {
        path: 'list',
        component: FaqListComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'edit/:id',
        component: FaqViewComponent,
        data: {
            mode: ViewMode.edit
        }
    },
    {
        path: 'view/:id',
        component: FaqViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: 'categories',
        component: CategoriesPageComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(FAQ_ROUTES),
        FaqListComponent,
        LanguageSelectorComponent,
        FaqViewComponent,
        CategoriesPageComponent
    ]
})
export class FaqModule {}
