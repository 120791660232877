import { JsonPipe } from '@angular/common';
import { Component, computed, inject, model } from '@angular/core';
import { CommonModule } from '@common/common.module';
import { FaqCategory } from 'app/faq/faq-categories.interface';
import { FaqCategoriesService } from 'app/faq/faq-categories.service';
import { FaqSaveModelCategory } from 'app/faq/faq.service';

@Component({
    selector: 'app-categories-selector',
    standalone: true,
    imports: [JsonPipe, CommonModule],
    templateUrl: './categories-selector.component.html'
})
export class CategoriesSelectorComponent {
    categoriesService = inject(FaqCategoriesService);
    categories = this.categoriesService.get({});

    selectedCategories = model<FaqSaveModelCategory[] | FaqCategory[]>([]);

    mappedSelectedCategories = computed(() => this.selectedCategories().map(this.mapFromFaqSaveModelCategory));
    mappedCategories = computed<{ label: string; value: number }[]>(() =>
        this.categories().data?.map((category) => ({ value: category.id, label: category.category }))
    );

    onValueChange(selected: number[]) {
        this.selectedCategories.set(selected.map((id) => ({ id })));
    }

    private mapFromFaqSaveModelCategory = (category: FaqSaveModelCategory | FaqCategory) => {
        return category.id;
    };
}
