import { Injectable, inject } from '@angular/core';
import { QueryService } from '@common/services/query.service';

export interface FaqGetParams {
    includeAllTranslations?: boolean;
    id?: number;
}

export interface FaqRemoveParams {
    id: number;
}

export type FaqSaveModelCategory = Pick<FaqCategory, 'id'>;

export interface FaqSaveModel {
    translations: FaqTranslation[];
    categories: FaqSaveModelCategory[];
}

export interface FaqTranslation {
    languageId: string;
    question: string;
    answer: string;
}

export type Get = Faq[];

export interface Faq extends FaqTranslation {
    id: number;
    categories?: FaqCategory[];
    categoriesFull?: FaqCategory[];
    translations?: FaqTranslation[];
}

export interface FaqCategory {
    id: number;
    category: string;
    languageId: string;
}

const endpoints = {
    get: 'Faq',
    remove: 'RemoveFaq',
    save: 'SaveFaq',
    getCategories: 'Categories',
    removeCategory: 'RemoveFaqCategory',
    saveCategory: 'SaveFaqCategory'
};

@Injectable({
    providedIn: 'root'
})
export class FaqService {
    private queryService = inject(QueryService);

    get(params: FaqGetParams = {}) {
        return this.queryService.getQuery<Get>(
            endpoints.get,
            { includeAllTranslations: false, ...params },
            {
                staleTime: Infinity
            }
        ).result;
    }

    remove(params: FaqRemoveParams) {
        return this.queryService
            .getCommandMutation<null, FaqRemoveParams>()
            .mutate({ command: endpoints.remove, data: params, invalidate: endpoints.get });
    }
}
