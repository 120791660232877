import { Injectable, inject } from '@angular/core';
import { I18nService } from '@common/services/i18n.service';
import { Action, QueryService, SaveCommand } from '@common/services/query.service';
import {
    FaqCategory,
    FaqCategoryCreateParams,
    FaqCategoryGet,
    FaqCategoryGetParams,
    FaqCategoryRemoveParams,
    FaqCategoryTranslation,
    FaqCategoryUpdateParams,
    FlattenedCategory
} from './faq-categories.interface';

const endpoints = {
    get: 'categories',
    remove: 'removeFaqCategory',
    save: 'saveFaqCategory'
};

@Injectable({
    providedIn: 'root'
})
export class FaqCategoriesService {
    private queryService = inject(QueryService);
    private i18nService = inject(I18nService);

    languages = this.i18nService.languages;
    languageIds = this.languages.map((l) => l.languageId.toUpperCase());
    initialCategory = this.languageIds.reduce((acc, l) => ({ ...acc, [l]: '' }), {});

    get(params: FaqCategoryGetParams) {
        return this.queryService.getQuery<FaqCategoryGet>(endpoints.get, params, { staleTime: Infinity }).result;
    }

    remove(params: FaqCategoryRemoveParams) {
        return this.queryService
            .getCommandMutation<null, FaqCategoryRemoveParams>()
            .mutate({ command: endpoints.remove, data: params, invalidate: endpoints.get });
    }

    update(params: FaqCategoryUpdateParams) {
        return this.queryService.getCommandMutation<FaqCategory, SaveCommand<FaqCategoryUpdateParams>>().mutate({
            command: endpoints.save,
            data: { action: Action.Edit, saveModel: { ...params } },
            invalidate: endpoints.get
        });
    }

    create(params: FaqCategoryCreateParams) {
        return this.queryService.getCommandMutation<FaqCategory, SaveCommand<FaqCategoryCreateParams>>().mutate({
            command: endpoints.save,
            data: { action: Action.Create, saveModel: { ...params } },
            invalidate: endpoints.get
        });
    }

    /**
     * The backend returns the categories with possibly missing translations, so we need to fill in the gaps to display in grid.
     * @param categories Categories returned from the API
     * @param languageIds For which we want to translate the categories
     * @returns array of translations for each category
     */
    populateCategoriesTranslations(categories: FaqCategory[]) {
        return categories?.map((c) => {
            const translations: FaqCategoryTranslation[] = [];
            this.languageIds.forEach((l) => {
                const t = c.translations?.find((t) => t.languageId === l);
                translations.push({ languageId: l, category: t?.category || '' });
            });
            return { ...c, translations };
        });
    }

    getLanguageIconClass(languageId: string) {
        return this.languages?.find(
            (l) => !languageId.localeCompare(l.languageId, undefined, { sensitivity: 'accent' })
        )?.iconClass;
    }

    flattenedTranslationsToTranslations(flattened: Record<string, string | number>): FaqCategoryTranslation[] {
        const translations: FaqCategoryTranslation[] = [];
        this.languageIds.forEach((l) => {
            translations.push({ languageId: l, category: flattened[l] as string });
        });
        return translations;
    }

    mapToFlattenedCategories(categoriesTranslations: FaqCategory[]): FlattenedCategory[] {
        return categoriesTranslations?.map((c) => {
            const translations = { ...this.initialCategory };
            c.translations.forEach((t) => {
                if (this.languageIds.includes(t.languageId)) {
                    translations[t.languageId] = t.category;
                }
            });
            return { ...translations, id: c.id };
        });
    }
}
