<app-content-header [actionBar]="actionBar" />
<app-card>
    @if (faqs().isLoading || categories().isLoading) {
        <app-loader />
    } @else {
        <div class="flex flex-col gap-4">
            <div class="flex flex-wrap justify-between gap-4">
                <app-text-input
                    id="search-input"
                    [placeholder]="'Search ...' | translate"
                    [isEditMode]="true"
                    [(value)]="faqFilter().search"
                    (valueChange)="handleSearchChange()" />
                <kendo-chiplist selection="multiple" (selectedChange)="categoriesSelectionHandler($event)">
                    @for (category of categories().data; track category.id) {
                        <kendo-chip>{{ category.category }}</kendo-chip>
                    }
                </kendo-chiplist>
            </div>
            <div>
                @if (filteredFaqs().length === 0) {
                    <div class="text-center">
                        {{ 'No FAQs available' | translate }}
                    </div>
                } @else {
                    @for (faq of filteredFaqs(); track faq.id) {
                        <kendo-expansionpanel>
                            <ng-template kendoExpansionPanelTitleDirective>
                                <div class="flex flex-col gap-1">
                                    <div class="flex gap-4">
                                        <span class="font-semibold text-card-foreground">
                                            {{ faq.question }}
                                        </span>
                                        @if (canCreateNew()) {
                                            <div class="edit-buttons">
                                                <span>
                                                    <a
                                                        class="btn btn-link btn-sm"
                                                        kendoTooltip
                                                        title="{{ 'Edit' | translate }}"
                                                        [routerLink]="['..', 'edit', faq.id]">
                                                        <ng-icon name="faSolidPenToSquare" />
                                                    </a>
                                                </span>
                                                <span>
                                                    <app-button
                                                        class="bg-transparent hover:text-danger"
                                                        tooltip="{{ 'Remove' | translate }}"
                                                        icon="faSolidTrash"
                                                        variant="link"
                                                        size="sm"
                                                        (clicked)="removeHandler(faq.id)" />
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    <div class="-ml-1 flex gap-0.5">
                                        @for (category of faq.categories; track category.id) {
                                            <span
                                                class="rounded-full bg-background-darker px-2.5 py-0.5 text-xs font-medium text-foreground/75">
                                                {{ category.category }}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </ng-template>
                            <div class="flex flex-col gap-6">
                                <div [innerHTML]="faq.answer"></div>
                            </div>
                        </kendo-expansionpanel>
                    }
                }
            </div>
        </div>
    }
</app-card>
